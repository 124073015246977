// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/confirmation.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/confirmation.tsx");
}
// REMIX HMR END

import { Link, useLoaderData } from '@remix-run/react';
import { Trans, useTranslation } from 'react-i18next';
import { Page } from '~/components/page/Page';
import { PageHeader } from '~/components/pageHeader/PageHeader';
import { Text } from '~/components/Text';
import { usePrefixedTranslation } from '~/hooks/usePrefixedTranslation';
import styles from './confirmation.module.css';
import { getSessionStorage } from '~/sessions';
import i18n from '~/i18n.server';
import { getPageTitle } from 'lib/get-page-title';
import { logger } from 'lib/logger';
import { OPEN_APP_URL, SUPPORT_URL } from '../../constants';
import { Button } from '~/components/Button';
import { FireworksAnimation } from '~/components/confirmation-route/FireworksAnimation';
import { ClientOnly } from 'remix-utils/client-only';
import { logPageView } from 'lib/log-page-view';
import { getPaymentSummaryFromIntent, getPaymentSummaryFromSubscriptionWithNoPayment } from 'lib/stripe/get-payment-summary';
import { amountToLocaleString } from 'lib/stripe/amount-to-locale-string';
import { useContext, useEffect } from 'react';
import Dinero from 'dinero.js';
import { add } from 'date-fns/add';
import { AnalyticsContext } from '~/AnalyticsContext';
import { PageHeaderBody } from '~/components/pageHeader/PageHeaderBody';
import { PageHeaderCopy } from '~/components/pageHeader/PageHeaderCopy';
import { PageHeaderHeading } from '~/components/pageHeader/PageHeaderHeading';
import { PageHeaderSubtitle } from '~/components/pageHeader/PageHeaderSubtitle';
import { getStripe } from '@bodycoach/lib/lambda/stripe';
import { environmentVariables } from 'lib/environment-variables';
import { json, redirect } from '@remix-run/node';
import { sendConfirmationEvents } from 'lib/send-confirmation-events';
import { StatusCodes } from 'http-status-codes';

/**
 * Indicates that a generic error has occurred on the confirmation page and the
 * user should be directed to contact a support hero.
 */
export const CONFIRMATION_PAGE_ERROR = 'confirmationPageError';

/**
 * Stripe doesn't allow us to strongly type this but these are payment intent
 * and subscription statuses which we regard as valid to display the
 * confirmation page to the user. If anything but these statuses are received
 * the user will be redirected back to /payment with an error message stating
 * that the payment has failed.
 *
 * If adding a value to this list ensure a corresponding localisation key is set
 * in the confirmation.json locale file.
 *
 * @see https://stripe.com/docs/api/payment_intents/object#payment_intent_object-status
 * @see https://stripe.com/docs/api/subscriptions/object#subscription_object-status
 */
const validStatuses = ['active', 'succeeded', 'processing', 'trialing'];
export async function loader({
  request
}) {
  const url = new URL(request.url);
  const {
    getSession,
    destroySession,
    redirectWithUpdatedSession
  } = await getSessionStorage(environmentVariables.ENVIRONMENT);
  const session = await getSession(request.headers.get('Cookie'));
  try {
    let summary;
    const intentId = url.searchParams.get('payment_intent') ?? url.searchParams.get('setup_intent');
    const subscriptionId = url.searchParams.get('subscription');
    const stripe = await getStripe(environmentVariables.ENVIRONMENT);
    if (intentId) {
      summary = await getPaymentSummaryFromIntent(stripe, intentId);
    } else if (subscriptionId) {
      summary = await getPaymentSummaryFromSubscriptionWithNoPayment(stripe, subscriptionId);
    } else {
      /**
       * Sometimes bots and crawlers inadvertently end up on this page. If we've
       * not been able to determine a payment summary based on the query params
       * (which is how we've ended up in this branch) there's nothing useful we
       * can show to the "user" so a redirect is the next best alternative.
       */
      return redirect('/', StatusCodes.SEE_OTHER);
    }
    logger.info({
      summary
    }, 'Got payment summary for subscription');
    const t = await i18n.getFixedT(request);
    if (!validStatuses.includes(summary.paymentStatus)) {
      session.flash('errorMessage', t('payment.paymentError') ?? undefined);
      return await redirectWithUpdatedSession('/payment', session);
    }
    logPageView(request.url);
    await sendConfirmationEvents(request, summary, session.data);
    return json({
      title: getPageTitle(t, 'confirmation.paymentConfirmation'),
      ...summary
    }, {
      headers: {
        'Set-Cookie': await destroySession(session)
      }
    });
  } catch (err) {
    logger.error({
      err,
      session: session.data,
      request
    }, 'Unable to present confirmation page');
    throw new Response(CONFIRMATION_PAGE_ERROR, {
      status: 500
    });
  }
}
export const meta = ({
  data,
  matches
}) => [{
  title: data?.title
},
// google read aloud will trigger a page reload to process, this stops the reload : https://optimizely.blog/2023/12/google-read-aloud-reload-problems/
{
  name: 'google',
  content: 'nopagereadaloud'
}, ...matches.flatMap(match => match.meta ?? [])];
function getSubtitleKey(freeTrialDays, firstPeriodDiscounted = false, upfrontPaymentRequired = true, isB2b = false) {
  if (isB2b) {
    return null;
  } else if (upfrontPaymentRequired && firstPeriodDiscounted && freeTrialDays) {
    return 'subtitleFreeTrialFirstPeriodDiscount';
  } else if (firstPeriodDiscounted) {
    return 'subtitleFirstPeriodDiscount';
  } else if (upfrontPaymentRequired && freeTrialDays) {
    return 'subtitleFreeTrial';
  } else if (!upfrontPaymentRequired && freeTrialDays) {
    return 'subtitleFreeTrialNoPayment';
  } else if (upfrontPaymentRequired) {
    return 'subtitle';
  }
}
export default function Confirmation() {
  _s();
  const {
    t
  } = usePrefixedTranslation('confirmation');
  const {
    t: globalT,
    i18n
  } = useTranslation();
  const {
    paymentStatus,
    total,
    subtotal,
    currency,
    interval,
    email,
    hashedEmail,
    freeTrialDays,
    firstPeriodDiscounted,
    upfrontPaymentRequired,
    isB2b,
    subscriptionId,
    subscriptionName
  } = useLoaderData();
  const showSubtitle = total && subtotal && currency || !upfrontPaymentRequired;
  const subtitleKey = getSubtitleKey(freeTrialDays, firstPeriodDiscounted, upfrontPaymentRequired, isB2b);
  const freeTrialEndDate = add(new Date(), {
    days: freeTrialDays
  });
  const {
    pushGTMVars: pushGTMVariables
  } = useContext(AnalyticsContext);
  useEffect(() => {
    pushGTMVariables({
      sha256_email_address: hashedEmail,
      subscription: {
        name: subscriptionName,
        value: Dinero({
          amount: total,
          currency: currency
        }).toRoundedUnit(2),
        id: subscriptionId,
        currency,
        freeTrialDays,
        interval
      }
    });
  }, []);
  return <Page className={styles.page}>
      <PageHeader as="div" className={styles.header} divider={false}>
        <PageHeaderBody>
          <PageHeaderCopy>
            <PageHeaderHeading title={t(`title.${isB2b ? 'b2b' : paymentStatus}`)} />
            <PageHeaderSubtitle subtitle={showSubtitle && subtitleKey ? <Trans t={t} i18nKey={subtitleKey} values={{
            total: amountToLocaleString(total, currency, i18n.language),
            subtotal: amountToLocaleString(subtotal, currency, i18n.language),
            interval: globalT(`common.plan.interval.${interval}`),
            period: globalT(`common.plan.period.${interval}`),
            trialExpiry: Intl.DateTimeFormat(i18n.language, {
              month: 'long',
              day: 'numeric'
            }).format(freeTrialEndDate),
            count: freeTrialDays
          }} components={[<strong />]} /> : null} />

          </PageHeaderCopy>
          <div className={styles.body}>
            <img src="/img/confirmation.svg" alt="" width="256" height="256" />

            {email ? <>
                <Text as="p" size="xl" weight={500}>
                  {t('toGetStarted')}
                </Text>

                <Text as="p" size="xl" className={styles.email}>
                  {email}
                </Text>
              </> : null}

            <Button as="a" className={styles.downloadApp} to={OPEN_APP_URL} invert={true}>
              {t('downloadApp')}
            </Button>

            <div className={styles.description}>
              <Text as="p">{t('description.0')}</Text>
              <Text as="p">
                <Trans t={t} i18nKey="description.1" components={[<Link to={SUPPORT_URL} target="_blank" />]} />
              </Text>
              {!upfrontPaymentRequired && !isB2b ? <Text as="p">{t('descriptionFreeTrialNoPayment')}</Text> : null}
            </div>

            <ClientOnly>{() => <FireworksAnimation />}</ClientOnly>
          </div>
        </PageHeaderBody>
      </PageHeader>
    </Page>;
}
_s(Confirmation, "wyshGCkaOuiME7Qf5qEoxlakCT4=", false, function () {
  return [usePrefixedTranslation, useTranslation, useLoaderData];
});
_c = Confirmation;
var _c;
$RefreshReg$(_c, "Confirmation");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;